<template>
  <div>
    <h3>Destroyed Terra</h3>
    <p>Terra has been demolished by Armageddon.</p>
    <p v-if="nextReset > now">The REINCARNATION will start again {{ nextReset | timestamp }}.</p>
  </div>
</template>

<script>
export default {
  name: 'ArmageddonComponent',
  data: function() {
    return {
      now: Date.now()
    }
  },
  props: {
    nextReset: Number
  },
  filters: {
    timestamp: function (value) {
      /* eslint-disable no-undef */
      return moment(value).format('ddd, MMM DD hh:mm:ss YYYY');
      /* eslint-enable no-undef */
    }
  },
}
</script>