<template>
  <div>
    <div class="d-flex justify-content-center text-smaller" v-if="running">
      <div class="p-w">
        The server was reset {{ startDate | timestamp }}.
      </div>
      <div class="p-w">
        Armageddon is scheduled for {{ armageddonStart | timestamp }}.
      </div>
    </div>
    <ErrorComponent v-if="errors.length > 0" :errors="errors"/>
    <ArmageddonComponent v-if="!running && errors.length === 0" :nextReset="startDate" />
    <div v-if="loggedIn && running">
      <MainComponent v-if="!reincarnate" :url="url" :mages="mages"/>
      <div v-if="magesPerUser > mages.length">
        <button type="button" class="btn btn-primary" v-if="!reincarnate" v-on:click="showReincarnate">
          Create new mage
        </button>
        <ReincarnateComponent v-if="reincarnate"/>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorComponent from './components/Error.vue';
import MainComponent from './components/Main.vue';
import ArmageddonComponent from './components/Armageddon.vue';
import ReincarnateComponent from './components/Reincarnate.vue';

export default {
  name: 'GamePortal',
  data: function() {
    return {
      magesPerUser: 0,
      startDate: 0,
      loggedIn: true,
      running: false,
      armageddonStart: 0,
      reincarnate: false,
      errors: [],
      mages: []
    };
  },
  props: {
    url: String
  },
  components: {
    ArmageddonComponent,
    ErrorComponent,
    MainComponent,
    ReincarnateComponent
  },
  filters: {
    timestamp: function (value) {
      /* eslint-disable no-undef */
      return moment(value).tz('UTC').format('ddd, MMM DD hh:mm:ss YYYY z');
      /* eslint-enable no-undef */
    }
  },
  mounted: function() {
    this.$on('reincarnate', (show) => {
      this.showReincarnate(show);
    });
    this.$on('createMage', (action) => {
      this.post('/api/mage/create', action).then((result) => {
        /* eslint-disable no-console */
        console.log(result);
        /* eslint-enable no-console */
        this.loadMages();
      });
      this.reincarnate = false;
    });

    this.post('/api/batch', [
      this.url + '/api/setting/server-period',
      this.url + '/api/setting/mages-per-user'
    ]).then((json) => {
      if (json[0].status === 200) {
        const serverPeriod = json[0].body;
        const startDate = serverPeriod.start;
        const endDate = serverPeriod.end;
        this.startDate = startDate;
        this.armageddonStart = serverPeriod.armageddon;

        const now = Date.now();
        this.running = now < endDate;
      }
      if (json[1].status === 200) {
        this.magesPerUser = json[1].body;
      }
    });

    this.loadMages();
  },
  methods: {
    showReincarnate(show) {
      this.reincarnate = show;
      this.errors.splice(0, this.errors.length);
    },
    errorHandler(response) {
      if (!response.ok) {
        if (response.status === 401) {
          this.errors.push('Please log in');
          this.loggedIn = false;
          throw Error(response.statusText);
        } else {
          response.json().then((json) => {
            /* eslint-disable no-console */
            console.log(json);
            /* eslint-enable no-console */
            if(json.exception === 'Armageddon') {
              throw Error('Armageddon');
            } else if(json.message && json.message.text) {
              const error = json.message.text;
              this.errors.push(error);
              throw Error(error);
            } else {
              const error = response.status + ' ' + response.statusText;
              this.errors.push(error);
              throw Error(error);
            }
          });
        }
      }
    },
    get(path) {
      return fetch(this.url + path, {
        credentials: 'include'
      }).then((response) => {
        this.errorHandler(response);
        return response.json();
      });
    },
    post(path, body, headers) {
      var allheaders = Object.assign({
          "Content-Type": 'application/json'
        }, headers);

      return fetch(this.url + path, {
        method: 'POST',
        credentials: 'include',
        headers: allheaders,
        body: JSON.stringify(body)
      }).then((response) => {
        this.errorHandler(response);
        return response.json();
      });
    },
    graphql(query, mageId) {
      const body = {
        operationName: 'query',
        variables: { id: mageId },
        query: query};
      
      return this.post('/graphql', body, { 'MageId': mageId });
    },

    loadMages() {
      this.mages = [];
      this.get('/api/mage').then((mageIds) => {
        /* eslint-disable no-console */
        console.log(mageIds);
        /* eslint-enable no-console */
        for (var i = 0; i < mageIds.length; i++) {
          const mageId = mageIds[i];
          const query = `
query Mage($id:Int!) {
  Mage(id:$id) {
    id
    name
    color
    rank
    land
    netPower
    status
    buildings { fortress { amount } }
    turns maxTurns
    mana maxMana
    population maxPopulation
    gold
    guild { shortName }
  }
}`;
          this.graphql(query, mageId).then((json) => {
            const mage = json.data.Mage;
            mage.events = 0;
            mage.messages = 0;

            this.mages.push(mage);
            this.mages.sort((a,b) => a.id - b.id);

            // Get events or messages
            this.post('/api/batch', [
              this.url + '/api/mage/' + mageId + '/log/events/new',
              this.url + '/api/mage/' + mageId + '/chat/new/size'
            ]).then((json) => {
              if (json[0].status === 200) {
                mage.events = json[0].body;
              }
              if (json[1].status === 200) {
                mage.messages = json[0].body;
              }
            });
          });
        }
      });
    }
  }
}
</script>
