<template>
  <div>
    <h3>Create a new Mage</h3>
    <form role="form" class="row" style="justify-content: center" v-on:submit.prevent="submit">
      <div class="col-sm-5">
        <div class="form-group row">
          <label for="ID" class="col-sm-4 col-form-label">Thy Aliad</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" name="ID" id="name" required v-model="name">
          </div>
        </div>

        <div class="form-group row">
          <label for="COLOR" class="col-sm-4 col-form-label">Magic Speciality</label>
          <div class="col-sm-8">
            <select name="COLOR" class="form-control" v-model="color">
              <option selected value="RANDOM">Random</option>

              <option value="ERADICATION">Eradication Magic</option>
              <option value="PHANTASM">Phantasm Magic</option>
              <option value="ASCENDANT">Ascendant Magic</option>
              <option value="NETHER">Nether Magic</option>
              <option value="VERDANT">Verdant Magic</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <button type="submit" class="btn btn-primary" style="margin-bottom: 1rem">Reincarnate</button>
        <button type="buttom" class="btn btn-secondary" v-on:click="cancel" style="margin-bottom: 1rem">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
const COLORS = ['ASCENDANT','ERADICATION','NETHER','PHANTASM','VERDANT'];
export default {
  name: 'ReincarnateComponent',
  data: function() {
    return {
      name: '',
      color: 'RANDOM'
    };
  },
  methods:{
    submit: function() {
      var color;
      if (this.color === 'RANDOM') {
        const randomIndex = Math.floor(Math.random() * 5);
        color = COLORS[randomIndex];
      } else {
        color = this.color;
      }

      this.$parent.$emit('createMage', { 'mageName': this.name, 'color': color});
      this.name = '';
      this.color = 'RANDOM';
    },
    cancel: function() {
      this.$parent.$emit('reincarnate', false);
    }
  }
}
</script>
