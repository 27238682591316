<template>
  <div>
    <h3 class="text-danger">Error</h3>
    <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorComponent',
  props: ['errors']
}
</script>