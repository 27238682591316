<template>
  <div class="row justify-content-center">
    <table class="table">
      <thead>
        <tr>
          <th>Ranking</th>
          <th></th>
          <th>Name</th>
          <th>Land</th>
          <th>Fortress</th>
          <th>Power</th>
          <th>Guild</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody v-for="mage in mages" :key="mage.id">
        <tr>
          <td data-th="Ranking">{{ mage.rank}}</td>
          <td data-th="Speciality"><span :class="getColorClass(mage.color)"/></td>
          <td data-th="Name"><a :href="url + '/classic/' + mage.id" target="_blank">{{ mage.name }} (#{{mage.id}})</a></td>
          <td data-th="Land">{{ mage.land }}</td>
          <td data-th="Fortress">{{ mage.buildings.fortress.amount }}</td>
          <td data-th="Power">{{ mage.netPower | number }}</td>
          <td data-th="Guild"><span v-if="mage.guild">{{ mage.guild.shortName }}</span></td>
          <td data-th="Status">{{ mage.status | shortStatus }}</td>
        </tr>
        <tr class="footer">
          <td colspan="8" class="text-smaller">
            <div class="p-w text-primary">
              <span v-if="mage.events">{{  mage.events }} Events </span> 
              <span v-if="mage.messages">{{ mage.messages }} Messages</span>
            </div>
            <div class="p-w">
              {{ mage.turns }} turn(s) available, {{ mage.maxTurns }} turn(s) used.
            </div>
            <div class="d-flex justify-content-center">
              <div class="p-w">{{ mage.mana | number }} / {{ mage.maxMana | number }} Magic Power</div>
              <div class="p-w">{{ mage.population | number }} / {{ mage.maxPopulation | number }} Population</div>
              <div class="p-w">{{ mage.gold | number }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const STATUS = {
  'APPRENTICE': 'A',
  'ACTIVE': '',
  'PILLAGED': '-',
  'DAMAGED': 'D',
  'PROTECTED': 'P',
  'FROZEN': 'F',
  'DEAD': 'D',
  'MEDITATING': 'M'
};

export default {
  name: 'MainComponent',
  props: ['url', 'mages'],
  filters: {
    number: function (value) {
      return value.toLocaleString();
    },
    shortStatus: function (value) {
      if (value in STATUS) {
        return STATUS[value];
      } else {
        return '?';
      }
    },

  },
  methods: {
    getColorClass: (value) => {
      return 'icon-' + value.toLowerCase();
    }
  }
}
</script>